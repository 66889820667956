import React from "react";
import moment from "moment";

const PrintPage = ({ values }) => {
  return (
    <>
      <section className="gold">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 hero_left">
              <img src="assets/images/log1.png" alt="" />
            </div>
            <div className="col-md-6 hero_right">
              <h4>Today's Gold Rate</h4>

              <div className="date">
                <img src="assets/images/cal.png" alt="" />
                <span>{moment(Date.now()).format("D MMM YYYY")}</span>
              </div>
              <ul className="hero_right_price_wrap">
                <li>
                  <img src="assets/images/shahimgm.png" alt="" />
                  <p>
                    <i className="fas fa-rupee-sign"></i> {values?.gm} /-
                  </p>
                </li>
                <li>
                  <img src="assets/images/shahimpvn.png" alt="" />
                  <p>
                    <i className="fas fa-rupee-sign"></i> {values?.pvn} /-
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrintPage;
