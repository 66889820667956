import {
  exportComponentAsJPEG,
  exportComponentAsPDF,
  exportComponentAsPNG,
} from "react-component-export-image";
import React, { useRef, useState } from "react";
import PrintPage from "./components/PrintPage";

const ComponentToPrint = React.forwardRef((props, ref) => (
  <div ref={ref}>
    <PrintPage values={props.values} />
  </div>
));

const MyComponent = () => {
  const componentRef = useRef();
  const [price, setPrice] = useState({
    gm: "",
    pvn: "",
  });

  return (
    <React.Fragment>
      <ComponentToPrint ref={componentRef} values={price} />
      <form>
        <div className="row m-5">
          <div className="col">
            <input
              className="form-control"
              placeholder="1gm Price"
              onChange={(e) => setPrice({ ...price, gm: e.target.value })}
            />
          </div>
          <div className="col">
            <input
              className="form-control"
              placeholder="1pvn Price"
              onChange={(e) => setPrice({ ...price, pvn: e.target.value })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col m-1">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => exportComponentAsJPEG(componentRef)}
            >
              Export JPEG
            </button>
          </div>
          <div className="col m-1">
            <button
              className="btn btn-info"
              onClick={() => exportComponentAsPDF(componentRef)}
            >
              Export PDF
            </button>
          </div>
          <div className="col m-1">
            <button
              className="btn btn-info"
              onClick={() => exportComponentAsPNG(componentRef)}
            >
              Export PNG
            </button>
          </div>
        </div>
      </form>
      <br />
      <div class="d-flex justify-content-center">
        <div class="col-lg-6 col-md-7 col-sm-6">
          <p>
            Proudly Crafted by{" "}
            <a href="https://codeads.in/" target="_blank" rel="noreferrer">
              CodeAds Solutions
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyComponent;
